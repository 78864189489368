import {
  ActionStatusEnum,
  MocActionTypesEnum,
  convertDateTimeToUtcDate,
} from "./../utility/Common";
import { observable, flow, computed } from "mobx";
import * as api from "../utility/dm.api";
import moment from "moment";

class ReportStore {
  @observable errorMessage: string | null = null;

  @observable
  dashboardTotalMocStatusDetailReportData: MocStatusReportResponse[] = [];
  @observable getDashboardTotalMocStatusDetailReportLoading: boolean = false;
  getDashboardTotalMocStatusDetailReport = flow(function* (
    this: ReportStore,
    payload: DashBoardReportsPaylod
  ) {
    this.getDashboardTotalMocStatusDetailReportLoading = true;

    this.dashboardTotalMocStatusDetailReportData = [];
    try {
      const response: APIResponse<MocStatusReportResponse[]> =
        yield api.getDashboardTotalMocStatusDetailReport(payload);
      this.dashboardTotalMocStatusDetailReportData = response.data;
    } catch (error) {
    } finally {
      this.getDashboardTotalMocStatusDetailReportLoading = false;
    }
  }).bind(this);

  resetActionsData: DashbradActionCountReport = {
    lateCompletation: 0,
    completed: 0,
    pending: 0,
    overdue: 0,
  };

  @computed get dashboardTotalctionsDetails() {
    return {
      lateCompletation: this.dashboardActionsBeforeStartup.lateCompletation + this.dashboardActionsAfterStartup.lateCompletation,
      completed: this.dashboardActionsBeforeStartup.completed + this.dashboardActionsAfterStartup.completed,
      pending: this.dashboardActionsBeforeStartup.pending + this.dashboardActionsAfterStartup.pending,
      overdue: this.dashboardActionsBeforeStartup.overdue + this.dashboardActionsAfterStartup.overdue,
    };
  }
  @observable dashboardActionsBeforeStartup: DashbradActionCountReport =
    this.resetActionsData;
  @observable dashboardActionsBeforeStartupLoading: boolean = false;
  getDashboardActionsBeforeStartup = flow(function* (
    this: ReportStore,
    payload: DashboardActionCountReportPayload
  ) {
    this.dashboardActionsBeforeStartupLoading = true;
    this.dashboardActionsBeforeStartup = this.resetActionsData;
    try {
      const ActionsBeforeStartupLateCompletation: APIResponse<number> =
        yield api.getDashboardActionCountReport({
          ...payload,
          mocActionsTypeId: MocActionTypesEnum.BeforeStartup,
          standingStatusId: ActionStatusEnum.LateCompletion,
        });
      const ActionsBeforeStartupPending: APIResponse<number> =
        yield api.getDashboardActionCountReport({
          ...payload,
          mocActionsTypeId: MocActionTypesEnum.BeforeStartup,
          standingStatusId: ActionStatusEnum.Pending,
        });
      const ActionsBeforeStartupOverDue: APIResponse<number> =
        yield api.getDashboardActionCountReport({
          ...payload,
          mocActionsTypeId: MocActionTypesEnum.BeforeStartup,
          standingStatusId: ActionStatusEnum.Overdue,
        });
      const ActionsBeforeStartupCompleted: APIResponse<number> =
        yield api.getDashboardActionCountReport({
          ...payload,
          mocActionsTypeId: MocActionTypesEnum.BeforeStartup,
          standingStatusId: ActionStatusEnum.Completed,
        });
      this.dashboardActionsBeforeStartup = {
        completed: ActionsBeforeStartupCompleted.data,
        lateCompletation: ActionsBeforeStartupLateCompletation.data,
        overdue: ActionsBeforeStartupOverDue.data,
        pending: ActionsBeforeStartupPending.data,
      };
    } catch (error) {
    } finally {
      this.dashboardActionsBeforeStartupLoading = false;
    }
  }).bind(this);

  @observable dashboardActionsAfterStartup: DashbradActionCountReport =
    this.resetActionsData;
  @observable dashboardActionsAfterStartupLoading: boolean = false;

  getDashboardActionsAfterStartup = flow(function* (
    this: ReportStore,
    payload: DashboardActionCountReportPayload
  ) {
    this.dashboardActionsAfterStartupLoading = true;
    this.dashboardActionsAfterStartup = this.resetActionsData;
    try {
      const ActionsAfterStartupLateCompletation: APIResponse<number> =
        yield api.getDashboardActionCountReport({
          ...payload,
          mocActionsTypeId: MocActionTypesEnum.AfterStartup,
          standingStatusId: ActionStatusEnum.LateCompletion,
        });
      const ActionsAfterStartupPending: APIResponse<number> =
        yield api.getDashboardActionCountReport({
          ...payload,
          mocActionsTypeId: MocActionTypesEnum.AfterStartup,
          standingStatusId: ActionStatusEnum.Pending,
        });
      const ActionsAfterStartupOverDue: APIResponse<number> =
        yield api.getDashboardActionCountReport({
          ...payload,
          mocActionsTypeId: MocActionTypesEnum.AfterStartup,
          standingStatusId: ActionStatusEnum.Overdue,
        });
      const ActionsAfterStartupCompleted: APIResponse<number> =
        yield api.getDashboardActionCountReport({
          ...payload,
          mocActionsTypeId: MocActionTypesEnum.AfterStartup,
          standingStatusId: ActionStatusEnum.Completed,
        });
      this.dashboardActionsAfterStartup = {
        completed: ActionsAfterStartupCompleted.data,
        lateCompletation: ActionsAfterStartupLateCompletation.data,
        overdue: ActionsAfterStartupOverDue.data,
        pending: ActionsAfterStartupPending.data,
      };
    } catch (error) {
    } finally {
      this.dashboardActionsAfterStartupLoading = false;
    }
  }).bind(this);

  @computed get dashboardActionTrendChartData() {
    const dashboardtrenddata: { x: string[]; y: number[] } = { x: [], y: [] };
    this.dashboardActionsTrendData.forEach((x) => {
      const dayOfWeek = moment(x[0]).format("");
      dashboardtrenddata.x.push(dayOfWeek);
      dashboardtrenddata.y.push(x[1]);
    });
    return dashboardtrenddata;
  }

  @observable dashboardActionsTrendData: [] = [];
  @observable DashboardActionsTrendReportLoading: boolean = false;
  getDashboardActionsTrendReport = flow(function* (
    this: ReportStore,
    payload: DashboardMocActionTrendReportPayload,
  ) {
    this.DashboardActionsTrendReportLoading = true;
    this.dashboardActionsTrendData = [];
    try {
      const response: APIResponse<[]> = yield api.getDashboardActionTrendReport(payload);
      this.dashboardActionsTrendData = response.data;
    } catch (error) { 
    } finally {
      this.DashboardActionsTrendReportLoading = false;
    }
  }).bind(this);

  changeDateTimeUTC(date: string): string {
    return convertDateTimeToUtcDate(date);
  }
}

export default ReportStore;
