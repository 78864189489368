import moment from "moment";
import { toast } from "react-toastify";
import { apiHost } from "./setting";
import queryString from "query-string";
import {history} from "../history";

export const SetAuthToken = (token: string): void => {
  localStorage.setItem("mocauth", token);
};

export const GetAuthToken = (): string => {
  return localStorage.getItem("mocauth") as string;
};

export const RemoveAuthToken = () => {
  localStorage.removeItem("mocauth");
};

export const VerifyAuthToken = (): boolean => {
  return (
    localStorage.getItem("mocauth") !== "" &&
    localStorage.getItem("mocauth") !== null
  );
};

export const makeID = (length) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const decryptToken = (): { userID: string; RoleID: string } => {
  const token = GetAuthToken();
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  const backendToken = JSON.parse(JSON.parse(jsonPayload).token);
  return { userID: backendToken.UserId, RoleID: backendToken.RoleId };
};


export const convertDateTimeToUtcDate = (date: any): string =>
  moment(date).utc().format("YYYY-MM-DD");

export const initPaginateListPayloadGlobal: PaginatedListPayload = {
  page: 0,
  pageSize: 10000000,
  totalRecords: 0,
  search: "",
  sortBy: [{ name: "Name", direction: "asc" }],
};

export function initPaginateListResponseGlobal<T>(
  initData: T
): PaginatedListResponse<T> {
  return {
    page: 0,
    pageSize: 1000,
    totalRecords: 0,
    search: "",
    sortBy: [{ name: "Name", direction: "asc" }],
    data: initData,
  };
}

export function showErrorNotification(message: string) {
  toast.error(message, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function showSuccessNotification() {
  toast.success("Operation completed successfully", {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export function getImageHost() {
  return apiHost;
}

export enum EnumAppRights {
  DepartmentManagement = "143376B9-DD20-4A02-8860-2345A627E975",
  RoutesManagement = "63D7AA6A-4834-48E1-8C51-480D6E09FC94",
  FixRequestsManagement = "A8A060AF-F6D2-4BB8-88E6-4D29220115E8",
  MocManagement = "E79BAD41-67AF-466F-8930-5A0B81215E8B",
  EquipmentManagement = "931E85CE-8691-4E23-9D07-5E458E438895",
  ConsiderationManagement = "3A64BD7F-204A-4162-AAB3-6EFD0875D90E",
  BranchManagement = "2244AE96-7F11-4E6A-8042-7C46CB45ADA5",
  DailyReport = "5A7E4EF7-DD10-4F05-98D6-9A1F83FDBB99",
  UsersManagement = "8AD500D6-9D98-41CB-A216-9DEA9EB4DCC6",
  SubSectionsManagement = "B0473AD3-505C-473A-A4A9-B26AD26217C2",
  MaindatesManagement = "9D5D332D-00C9-4E56-B47C-B8A6193ED9BA",
  ChangeCategoryManagement = "AFB79426-A5E2-460F-8B01-CFED3710A21B",
  Dashboard = "D5A4C11C-A704-41A4-8C55-EC4F090E0FA3",
  RolesRightsManagement = "DD003CD0-55FC-424F-9726-F632E36AB645",
  EquipmentHealthReport = "3A598F3C-95FD-4550-9E8C-F6D491349806",
  AssetManagement = "0390AAC1-5950-4668-9B0A-E40B3DC9542D",
  Notification = "0FCABD11-ED60-4661-B479-61228DA6D1FE",
  TeamsManagement = "E144FE8B-2160-45EB-A8BE-8765E98E6358",
}

export enum EnumAccessType {
  Update = '7408B8AA-A35A-4BF4-96DD-1E1D409E58B1',    
  Write = '3CB70C35-9CD9-4C84-98D6-A2594CB37239',
  Read = 'D37C19A0-F851-4D2F-81D2-B46FC8E6A0B4',
  Delete = '03A2BB84-C87C-49D5-9442-BFED638E78BA',
  Special = '520D242F-BBFD-408E-BA8A-D9A7DC01DADF'
}

export enum UploadMediaFileType {
  Documents = 1,
  Image = 2,
};
  
export const getQueryString = (paramName: string): string => {
  const queryParams: any = queryString.parse(history.location.search);
  return queryParams[paramName];
};

export enum MocRiskLevel {
  Low = "A91BEB97-E573-4269-BB1A-47396DEF84E9",
  Medium = "3C76FB2C-4849-474C-9893-C2C430F50CF7",
};

export enum MocType {
  Temporary = "BE829B85-47E1-4A5F-8E46-9598B0128F0F",
  Permanent = "0AA9B610-C338-4E7B-B811-617C4557AC7F",
  TestRun = '2DBC2B5E-E7CC-49CB-A7B5-A25F7BF1C08B',
};

export const MocstatusEnum: MasterListResponse[] = [
  {
    id: "D9742F51-C507-4961-BCC7-F7CDD78B2BB1",
    name: "Yes",
  },
  {
    id: "99B28246-41A6-41F5-AAB8-B10D118285F5",
    name: "No",
  },
  {
    id: "451F7622-71D8-4D39-B744-8C2A85CAD1AA",
    name: "N/A",
  },
]

export const MocActionTypes: MasterListResponse[] = [
  {
    id: "CA477924-86ED-498E-A333-92FD080E779A",
    name: "After Startup"
  },
  {
    id: "04482723-AD46-4633-B43C-AA681176187F",
    name: "Before Startup"
  }
]

export enum UploadMediaTypes {
  Image=  "A11BED41-F39E-449E-8D56-32DA5E67892F",
  Document = "3CB21F3F-2F23-4AAC-B3E8-59BE698D6591",
  Video = "A5BB7112-A063-4086-AF83-AE7F0F596AD5",
  Audio = "8CDD91BE-4DDB-4736-9E8C-C29A0AD684FC"    
}

export enum ActionStatusEnum {
  LateCompletion = "ED686E28-0428-45F0-8B54-498744EF1E62",
  Pending = "4501C40D-1869-43B4-A59D-9A184C113B37",
  Completed = "CE959F94-B4A2-4B8D-ABC1-DB1AFAF95529",
  Overdue = "7EE11296-3D24-4689-81C4-BEF800B5AF2E"
}

export enum MocActionTypesEnum{
  AfterStartup = "CA477924-86ED-498E-A333-92FD080E779A",
  BeforeStartup = "04482723-AD46-4633-B43C-AA681176187F"
}

export enum MocValidationId {
  PostValidation = "AB673BE7-9A76-4567-AFC3-2C5951C990B3",
  PreValidation = "8A043CB0-827F-4AF4-89E5-55869E8B314D"
}

export enum MocCurrentStatusEnum {
  Closed = "F9B9C93B-F3AF-48EC-AA13-E489F5081729",
  PostStartup = "F6AFC5E2-9B0A-4AF9-AEAE-E31F873F01F0",
  PreValidated = "EE809696-B0FF-41D4-BFB9-FE05987627DA",
  Returned = "189D4025-ED01-4B9E-9860-D391C80DF761",
  Approved = "5f103444-4aa2-4419-b16e-5fbdb69d3124",
  Startup = "ECFEDBA0-80F2-4E66-A6EE-ABBF95316442",
  ApprovalAwaited = "CECF0CDF-D5AF-4CFC-BD67-9CB4341458CA",
  PreStartup = "B2E6B896-53DF-4715-B6C2-9A808DC4DB2D",
  Initiated = "F68AB0A3-4349-420B-BEB5-60FC65CCE186",
  PSSR = "484C3BAA-CF6A-4800-AC85-6D2FDBD7932E",
  Floated = "10D94234-4111-47EB-B317-0D6FF442DCAD",
  PostValidationFaild = "611E3DA9-62A1-4AAD-AB4F-53036D1EA33B",
  ExecutionUndergo = "ECFEDBA0-80F2-4E66-A6EE-ABBF95316442",
  PSSRCalled = "484C3BAA-CF6A-4800-AC85-6D2FDBD7932E",
  BSTaskUndergo = "22DFA287-0A2A-4057-996B-D9A13DFD33F2",
  ExecutionDone = "9F89F2BF-F0B3-4107-87B3-B9C99D78E6E4",
  BSValidationCalled = "F6AFC5E2-9B0A-4AF9-AEAE-E31F873F01F0",
  ASValidationCalled = "59695DBD-D261-4910-8432-87B0B99D4A65",
  ASTaskUndergo = "896AD73D-6422-487F-89A1-CAA1B57F3EAD",
  PostValidationCalled = "611E3DA9-62A1-4AAD-AB4F-53036D1EA33B",
  PSSRDone = "B2E6B896-53DF-4715-B6C2-9A808DC4DB2D",
}

export const isGUID =(input)=> {
  const guidRegex = /^[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}$/;
  return guidRegex.test(input);
}