import { observable, flow } from "mobx";
import * as api from "../utility/dm.api";

export default class MasterDataStore {
  @observable loading: MasterDataLoading = {
    assetStatusLoading: false,
    equipmentTypesLoading: false,
    ipFacultiesLoading: false,
    ConsiderationTypeLoading: false,
    standingStatusLoading: false,
    changeTypeLoading: false,
    approvalTypeLoading: false,
    mocActionsLoading: false,
  };
  @observable errorMessage: string | null = null;

  @observable equipmentTypes: MasterListResponse[] = [];

  getEquipmentTypes = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, equipmentTypesLoading: true };
    this.errorMessage = null;
    this.equipmentTypes = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getEquipmentTypes();
      this.equipmentTypes = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, equipmentTypesLoading: false };
    }
  }).bind(this);

  @observable ipFaculties: MasterListResponse[] = [];

  getIpFaculties = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, ipFacultiesLoading: true };
    this.errorMessage = null;
    this.ipFaculties = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getIpFaculties();
      this.ipFaculties = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, ipFacultiesLoading: false };
    }
  }).bind(this);

  @observable ConsiderationTypes: MasterListResponse[] = [];

  getConsiderationTypes = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, ConsiderationTypeLoading: true };
    this.errorMessage = null;
    this.ConsiderationTypes = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getConsiderationType();
      this.ConsiderationTypes = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, ConsiderationTypeLoading: false };
    }
  }).bind(this);

  @observable StandingStatus: MasterListResponse[] = [];

  getStandingStatus = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, standingStatusLoading: true };
    this.errorMessage = null;
    this.StandingStatus = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getStandingStatus();
      this.StandingStatus = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, standingStatusLoading: false };
    }
  }).bind(this);
  
  @observable ChangeType: MasterListResponse[] = [];

  getChangeType = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, changeTypeLoading: true };
    this.errorMessage = null;
    this.ChangeType = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getChangeType();
      this.ChangeType = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, changeTypeLoading: false };
    }
  }).bind(this);

  @observable ApprovalType: MasterListResponse[] = [];

  getApprovalType = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, approvalTypeLoading: true };
    this.errorMessage = null;
    this.ApprovalType = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getApprovalType();
      this.ApprovalType = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, approvalTypeLoading: false };
    }
  }).bind(this);

  @observable MocActionsType: MasterListResponse[] = [];

  getMocActionsType = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, mocActionsLoading: true };
    this.errorMessage = null;
    this.MocActionsType = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getMocActionsType();
      this.MocActionsType = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, mocActionsLoading: false };
    }
  }).bind(this);

  @observable GetFaculties: MasterListResponse[] = [];

  getMocActionsType = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, mocActionsLoading: true };
    this.errorMessage = null;
    this.MocActionsType = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getMocActionsType();
      this.MocActionsType = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, mocActionsLoading: false };
    }
  }).bind(this);

  @observable MocChangesListData: MasterListResponse[] = [];

  getMocChanges = flow(function* (this: MasterDataStore) {
    this.loading = { ...this.loading, MocChangesLoading: true };
    this.errorMessage = null;
    this.MocChangesListData = [];
    try {
      const response: APIResponse<
        MasterListResponse[]
      > = yield api.getMocChanges();
      this.MocChangesListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = { ...this.loading, MocChangesLoading: false };
    }
  }).bind(this);
}
