import { showErrorNotification, showSuccessNotification } from "./Common";

export * from "./api/Common";
export * from "./api/CRUDS";
export * from "./api/Dashboard";
export * from "./api/MasterTables";
export * from "./api/Reports";
export * from "./api/RolesRights";

export function errorCallback(error) {
  let errorMessage = "";
  if('title' in error.body)
    errorMessage = error.body.title;
  if('message' in error.body)
    errorMessage = error.body.message;
  if('exception' in error.body && !error.body.exception.includes('Inner Exception'))
    errorMessage = error.body.exception;
  if(Array.isArray(error.body) && error.body.length > 0)
    errorMessage = error.body.join();
  if(error.statusCode === 400 && error.body['message'] !== error.body['exception'])
    errorMessage = "Invalid request. Please fill in the form carefully before submitting data. Thank you.";
  if(error.statusCode === 401)
    errorMessage = "Unauthorized Access. Please make sure, you are logged in and have a valid package. Thank you.";
  if(errorMessage === '')
    errorMessage = "Oops!! something went wrong, please try again later. Thank you.";

  showErrorNotification(errorMessage);

}

export function showSuccessNoti() {

  showSuccessNotification();

}

