import { createContext, useContext } from 'react';
import AdminStore from './AdminStore';
import DataStore from './DataStore';
import MasterDataStore from './MasterDataStore';
import AccountStore from './AccountStore';
import ReportStore from './ReportStore';
import ImportDataStore from './ImportDataStore';

export interface IStore {
  adminStore: AdminStore;
  dataStore: DataStore;
  masterDataStore: MasterDataStore;
  accountStore: AccountStore;
  reportStore: ReportStore;
  importDataStore: ImportDataStore;
}
export const store: IStore = {
  adminStore: new AdminStore(),
  dataStore: new DataStore(),
  masterDataStore: new MasterDataStore(),
  accountStore: new AccountStore(),
  reportStore: new ReportStore(),
  importDataStore: new ImportDataStore(),

};
export const StoreContext = createContext(store);
export const useStore = () => {
  return useContext(StoreContext);
};
