import * as request from "superagent";
import { errorCallback } from "utility/dm.api";
import { apiURL, qrCodeApi } from "utility/setting";
import { GetAuthToken } from "../Common";

const token = () => `Bearer ${GetAuthToken()}`;

/////////////////// IMport data///////////////////////////////

export const importEquipments = (
  payload: FormData
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/UploadDataIngest/uploadequipment/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const importAssets = (
  payload: FormData
): Promise<APIResponse<boolean>> =>
  request
    .post(`${apiURL}/UploadDataIngest/uploadasset/`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

////// Equipment QR //////////

export const getEquipmentQR = (
  equipment: EquipmentListResponse
): Promise<any> =>
  request
    .get(
      qrCodeApi(equipment.id)
    )
    .then((r: { text: any }) => ({ svgText: r.text, equipment }));
