import React from "react";
import { useStore } from "../../../stores/index";
import { observer } from "mobx-react-lite";

export const Trial: React.FC = observer(() => {
  const {
    accountStore: { userSubscriptions },
  } = useStore();

  return (
    <>
      {userSubscriptions &&
        userSubscriptions.find(
          (x) =>
            x.isActive === true &&
            x.packageId === "c09254ae-5aa9-4ea2-8aa0-45c50dc597a8"
        ) && (
          <div style= {{
            position: 'absolute',
            left: '0',
            background: 'rgba(255, 255, 0, 0.7)',
            width: '100%',
            height: '13px',
            bottom: '-7px',
          }}>
            <h3 style={{
              textAlign: 'center',
              fontSize: '8.5pt',
              color: 'black',
            }}>
              You are using trial version of Management of Change
            </h3>
          </div>
        )}
    </>
  );
});
