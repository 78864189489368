import { observable, flow, action } from "mobx";
import * as api from "../utility/dm.api";
import {
  UploadMediaTypes,
  initPaginateListResponseGlobal,
  isGUID,
} from "utility/Common";
import { init } from "ramda";
import { bool } from "prop-types";

export default class DataStore {
  @observable errorMessage: string | null = null;

  @observable loading: { [key: string]: DataLoading } = {
    packages: { ...initLoading },
    branch: { ...initLoading },
    section: { ...initLoading },
    subSection: { ...initLoading },
    asset: { ...initLoading },
    equipment: { ...initLoading },
    customerSubscription: { ...initLoading },
    teams: { ...initLoading },
    consideration: { ...initLoading },
    mandates: { ...initLoading },
    moc: { ...initLoading },
    action: { ...initLoading },
    equipmentType: { ...initLoading },
    department: { ...initLoading },
    mocCategory: { ...initLoading },
    mocConsiderationAction: { ...initLoading },
    mocConsidrationRemarks: { ...initLoading },
    mocApprovers: { ...initLoading },
    mocConsidrationMedia: { ...initLoading },
    mocApprovel: { ...initLoading },
    setMarkAsCompleted: { ...initLoading },
    mocChangeStatus: { ...initLoading },
    changeMocStatus: { ...initLoading },
    maindatesRemarksAgreement: { ...initLoading },
    considrationRemarksAgreement: { ...initLoading },
    mocConsiderationLogs: {...initLoading},
    mocMaindatesLogs: {...initLoading},
  };
  @observable saveDataResponse: APIResponse<boolean> | null = null;
  @observable deleteDataResponse: APIResponse<boolean> | null = null;

  @action.bound
  clearDeleteDataResponse() {
    this.deleteDataResponse = null;
  }

  @action.bound
  clearSaveDataResponse() {
    this.saveDataResponse = null;
  }

  @observable PackageListData: PaginatedListResponse<PackagesListResponse[]> =
    initPaginateListResponseGlobal<PackagesListResponse[]>([]);
  @observable packageData: Package | null = null;

  getPackages = flow(function* (
    this: DataStore,
    payload: PaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      packages: { ...this.loading.packages, listLoading: true },
    };
    this.PackageListData = initPaginateListResponseGlobal<
      PackagesListResponse[]
    >([]);
    try {
      const response: APIResponse<
        PaginatedListResponse<PackagesListResponse[]>
      > = yield api.getPackages(payload);
      this.PackageListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        packages: { ...this.loading.packages, listLoading: false },
      };
    }
  }).bind(this);

  getPackage = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      packages: { ...this.loading.packages, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.packageData = null;
    try {
      const response: APIResponse<Package> = yield api.getPackage(ID);
      this.packageData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        packages: { ...this.loading.packages, itemLoading: false },
      };
    }
  }).bind(this);

  savePackage = flow(function* (this: DataStore, payload: PackagePayload) {
    this.loading = {
      ...this.loading,
      packages: { ...this.loading.packages, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.savePackage(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        packages: { ...this.loading.packages, saveDataLoading: false },
      };
    }
  }).bind(this);

  deletePackage = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      packages: { ...this.loading.packages, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deletePackage(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        packages: { ...this.loading.packages, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable BranchesListData: PaginatedListResponse<BranchListResponse[]> =
    initPaginateListResponseGlobal<BranchListResponse[]>([]);
  @observable branchData: Branch | null = null;

  getBranches = flow(function* (
    this: DataStore,
    payload: ConfigPaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      branch: { ...this.loading.branch, listLoading: true },
    };
    this.BranchesListData = initPaginateListResponseGlobal<
      BranchListResponse[]
    >([]);

    try {
      const response: APIResponse<PaginatedListResponse<BranchListResponse[]>> =
        yield api.getBranches(payload);
      this.BranchesListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        branch: { ...this.loading.branch, listLoading: false },
      };
    }
  }).bind(this);

  getBranch = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      branch: { ...this.loading.branch, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.branchData = null;
    try {
      const response: APIResponse<Branch> = yield api.getBranch(ID);
      this.branchData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        branch: { ...this.loading.branch, itemLoading: false },
      };
    }
  }).bind(this);

  saveBranch = flow(function* (this: DataStore, payload: BranchPayload) {
    this.loading = {
      ...this.loading,
      branch: { ...this.loading.branch, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveBranch(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        branch: { ...this.loading.branch, saveDataLoading: false },
      };
    }
  }).bind(this);

  deleteBranch = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      branch: { ...this.loading.branch, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteBranch(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        branch: { ...this.loading.branch, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable SectionsListData: PaginatedListResponse<SectionListResponse[]> =
    initPaginateListResponseGlobal<SectionListResponse[]>([]);
  @observable sectionData: Section | null = null;

  getSections = flow(function* (
    this: DataStore,
    payload: ConfigPaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      section: { ...this.loading.section, listLoading: true },
    };
    this.SectionsListData = initPaginateListResponseGlobal<
      SectionListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<SectionListResponse[]>
      > = yield api.getSections(payload);
      this.SectionsListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        section: { ...this.loading.section, listLoading: false },
      };
    }
  }).bind(this);

  getSection = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      section: { ...this.loading.section, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.sectionData = null;
    try {
      const response: APIResponse<Section> = yield api.getSection(ID);
      this.sectionData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        section: { ...this.loading.section, itemLoading: false },
      };
    }
  }).bind(this);

  saveSection = flow(function* (this: DataStore, payload: SectionPayload) {
    this.loading = {
      ...this.loading,
      section: { ...this.loading.section, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveSection(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        section: { ...this.loading.section, saveDataLoading: false },
      };
    }
  }).bind(this);

  deleteSection = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      section: { ...this.loading.section, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteSection(ID, false);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        section: { ...this.loading.section, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable subSectionListData: PaginatedListResponse<
    SubSectionListResponse[]
  > = initPaginateListResponseGlobal<SubSectionListResponse[]>([]);
  @observable subSectionData: SubSection | null = null;

  getSubSections = flow(function* (
    this: DataStore,
    payload: ConfigPaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      subSection: { ...this.loading.subSection, listLoading: true },
    };
    this.subSectionListData = initPaginateListResponseGlobal<
      SubSectionListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<SubSectionListResponse[]>
      > = yield api.getSubSections(payload);
      this.subSectionListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        subSection: { ...this.loading.subSection, listLoading: false },
      };
    }
  }).bind(this);

  @observable exportSubSectionListDataLoading: boolean = false;
  getExportSubSections = flow(function* (this: DataStore) {
    this.exportSubSectionListDataLoading = true;

    try {
      yield api.exportSubSections();
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.exportSubSectionListDataLoading = false;
    }
  }).bind(this);

  getSubSection = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      subSection: { ...this.loading.subSection, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.subSectionData = null;
    try {
      const response: APIResponse<SubSection> = yield api.getSubSection(ID);
      this.subSectionData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        subSection: { ...this.loading.subSection, itemLoading: false },
      };
    }
  }).bind(this);

  saveSubSection = flow(function* (
    this: DataStore,
    payload: SubSectionPayload
  ) {
    this.loading = {
      ...this.loading,
      subSection: { ...this.loading.subSection, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveSubSection(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        subSection: { ...this.loading.subSection, saveDataLoading: false },
      };
    }
  }).bind(this);

  deleteSubSection = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      subSection: { ...this.loading.subSection, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteSubSection(ID, false);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        subSection: { ...this.loading.subSection, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable assetListData: PaginatedListResponse<AssetListResponse[]> =
    initPaginateListResponseGlobal<AssetListResponse[]>([]);
  @observable assetData: Asset | null = null;

  getAssets = flow(function* (
    this: DataStore,
    payload: ConfigPaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      asset: { ...this.loading.asset, listLoading: true },
    };
    this.assetListData = initPaginateListResponseGlobal<AssetListResponse[]>(
      []
    );

    try {
      const response: APIResponse<PaginatedListResponse<AssetListResponse[]>> =
        yield api.getAssets(payload);
      this.assetListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        asset: { ...this.loading.asset, listLoading: false },
      };
    }
  }).bind(this);

  @observable exportAssetListDataLoading: boolean = false;
  getExportAssets = flow(function* (this: DataStore) {
    this.exportAssetListDataLoading = true;

    try {
      yield api.exportAssets();
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.exportAssetListDataLoading = false;
    }
  }).bind(this);

  getAsset = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      asset: { ...this.loading.asset, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.assetData = null;
    try {
      const response: APIResponse<Asset> = yield api.getAsset(ID);
      this.assetData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        asset: { ...this.loading.asset, itemLoading: false },
      };
    }
  }).bind(this);

  saveAsset = flow(function* (this: DataStore, payload: AssetPayload) {
    this.loading = {
      ...this.loading,
      asset: { ...this.loading.asset, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveAsset(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        asset: { ...this.loading.asset, saveDataLoading: false },
      };
    }
  }).bind(this);

  deleteAsset = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      asset: { ...this.loading.asset, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteAsset(ID, true);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        asset: { ...this.loading.asset, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable equipmentListData: PaginatedListResponse<
    EquipmentListResponse[]
  > = initPaginateListResponseGlobal<EquipmentListResponse[]>([]);
  @observable equipmentData: Equipment | null = null;

  getEquipments = flow(function* (
    this: DataStore,
    payload: ConfigPaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      equipment: { ...this.loading.equipment, listLoading: true },
    };
    this.equipmentListData = initPaginateListResponseGlobal<
      EquipmentListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<EquipmentListResponse[]>
      > = yield api.getEquipments(payload);
      this.equipmentListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        equipment: { ...this.loading.equipment, listLoading: false },
      };
    }
  }).bind(this);

  @action
  clearEquipmentsListData = () => {
    this.equipmentListData = initPaginateListResponseGlobal<
      EquipmentListResponse[]
    >([]);
  };

  @observable exportEquipmentListDataLoading: boolean = false;
  getExportEquipments = flow(function* (this: DataStore) {
    this.exportEquipmentListDataLoading = true;

    try {
      yield api.exportEquipments();
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.exportEquipmentListDataLoading = false;
    }
  }).bind(this);

  getEquipment = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      equipment: { ...this.loading.equipment, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.equipmentData = null;
    try {
      const response: APIResponse<Equipment> = yield api.getEquipment(ID);
      this.equipmentData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        equipment: { ...this.loading.equipment, itemLoading: false },
      };
    }
  }).bind(this);

  saveEquipment = flow(function* (this: DataStore, payload: EquipmentPayload) {
    this.loading = {
      ...this.loading,
      equipment: { ...this.loading.equipment, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveEquipment(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        equipment: { ...this.loading.equipment, saveDataLoading: false },
      };
    }
  }).bind(this);

  deleteEquipment = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      equipment: { ...this.loading.equipment, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteEquipment(ID, true);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        equipment: { ...this.loading.equipment, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable customerSubscriptionListData: PaginatedListResponse<
    CustomerSubscriptionListResponse[]
  > = initPaginateListResponseGlobal<CustomerSubscriptionListResponse[]>([]);

  @observable customerSubscriptionData: CustomerSubscription | null = null;

  getCustomerSubscriptions = flow(function* (
    this: DataStore,
    payload: PaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      customerSubscription: {
        ...this.loading.customerSubscription,
        listLoading: true,
      },
    };
    this.customerSubscriptionListData = initPaginateListResponseGlobal<
      CustomerSubscriptionListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<CustomerSubscriptionListResponse[]>
      > = yield api.getCustomerSubscriptions(payload);
      this.customerSubscriptionListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        customerSubscription: {
          ...this.loading.customerSubscription,
          listLoading: false,
        },
      };
    }
  }).bind(this);

  getCustomerSubscription = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      customerSubscription: {
        ...this.loading.customerSubscription,
        itemLoading: true,
      },
    };

    this.saveDataResponse = null;
    this.customerSubscriptionData = null;
    try {
      const response: APIResponse<CustomerSubscription> =
        yield api.getCustomerSubscription(ID);
      this.customerSubscriptionData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        customerSubscription: {
          ...this.loading.customerSubscription,
          itemLoading: false,
        },
      };
    }
  }).bind(this);

  saveCustomerSubscription = flow(function* (
    this: DataStore,
    payload: CustomerSubscription
  ) {
    this.loading = {
      ...this.loading,
      customerSubscription: {
        ...this.loading.customerSubscription,
        saveDataLoading: true,
      },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveCustomerSubscription(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        customerSubscription: {
          ...this.loading.customerSubscription,
          saveDataLoading: false,
        },
      };
    }
  }).bind(this);

  deleteCustomerSubscription = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      customerSubscription: {
        ...this.loading.customerSubscription,
        deleteLoading: true,
      },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteCustomerSubscription(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        customerSubscription: {
          ...this.loading.customerSubscription,
          deleteLoading: false,
        },
      };
    }
  }).bind(this);

  @observable TeamsListData: PaginatedListResponse<Team[]> =
    initPaginateListResponseGlobal<Team[]>([]);
  @observable TeamsData: TeamPayload | null = null;

  getTeams = flow(function* (this: DataStore, payload: PaginatedListPayload) {
    this.loading = {
      ...this.loading,
      teams: { ...this.loading.teams, listLoading: true },
    };
    this.TeamsListData = initPaginateListResponseGlobal<Team[]>([]);

    try {
      const response: APIResponse<PaginatedListResponse<Team[]>> =
        yield api.getTeams(payload);
      this.TeamsListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        teams: { ...this.loading.teams, listLoading: false },
      };
    }
  }).bind(this);

  saveTeams = flow(function* (this: DataStore, payload: TeamPayload) {
    this.loading = {
      ...this.loading,
      teams: { ...this.loading.teams, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveTeams(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        teams: { ...this.loading.teams, saveDataLoading: false },
      };
    }
  }).bind(this);

  getTeam = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      team: { ...this.loading.team, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.TeamsData = null;
    try {
      const response: APIResponse<TeamPayload> = yield api.getTeam(ID);
      this.TeamsData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        teams: { ...this.loading.teams, itemLoading: false },
      };
    }
  }).bind(this);

  deleteTeam = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      teams: { ...this.loading.teams, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteTeam(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        teams: { ...this.loading.teams, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable ConsiderationListData: PaginatedListResponse<
    ConsiderationListResponse[]
  > = initPaginateListResponseGlobal<ConsiderationListResponse[]>([]);
  @observable ConsideratinData: ConsiderationListResponse | null = null;

  getConsiderations = flow(function* (
    this: DataStore,
    payload: PaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      consideration: { ...this.loading.consideration, listLoading: true },
    };
    this.ConsiderationListData = initPaginateListResponseGlobal<
      ConsiderationListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<ConsiderationListResponse[]>
      > = yield api.getConsiderations(payload);
      this.ConsiderationListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        consideration: { ...this.loading.consideration, listLoading: false },
      };
    }
  }).bind(this);

  getConsideration = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      consideration: { ...this.loading.consideration, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.ConsideratinData = null;
    try {
      const response: APIResponse<ConsiderationListResponse> =
        yield api.getConsideration(ID);
      this.ConsideratinData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        consideration: { ...this.loading.consideration, itemLoading: false },
      };
    }
  }).bind(this);

  saveConsideration = flow(function* (
    this: DataStore,
    payload: ConsideratinPayload
  ) {
    this.loading = {
      ...this.loading,
      consideration: { ...this.loading.consideration, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveConsideration(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        consideration: {
          ...this.loading.consideration,
          saveDataLoading: false,
        },
      };
    }
  }).bind(this);

  deleteConsideration = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      consideration: { ...this.loading.consideration, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteConsideration(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        consideration: { ...this.loading.consideration, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable MandatesListData: PaginatedListResponse<MandatesListResponse[]> =
    initPaginateListResponseGlobal<MandatesListResponse[]>([]);
  @observable MandatesData: MandatesListResponse | null = null;

  getMandates = flow(function* (
    this: DataStore,
    payload: PaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      mandates: { ...this.loading.mandates, listLoading: true },
    };
    this.MandatesListData = initPaginateListResponseGlobal<
      MandatesListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<MandatesListResponse[]>
      > = yield api.getMandates(payload);
      this.MandatesListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mandates: { ...this.loading.mandates, listLoading: false },
      };
    }
  }).bind(this);

  getMandate = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      mandates: { ...this.loading.mandates, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.MandatesData = null;
    try {
      const response: APIResponse<MandatesListResponse> = yield api.getMandate(
        ID
      );
      this.MandatesData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mandates: { ...this.loading.mandates, itemLoading: false },
      };
    }
  }).bind(this);

  saveMandate = flow(function* (this: DataStore, payload: MandatesPayload) {
    this.loading = {
      ...this.loading,
      mandates: { ...this.loading.mandates, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveMandate(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mandates: { ...this.loading.mandates, saveDataLoading: false },
      };
    }
  }).bind(this);

  deleteMandate = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      mandates: { ...this.loading.mandates, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteMandate(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mandates: { ...this.loading.mandates, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable ActionsListResponse: PaginatedListResponse<
    ActionsListResponse[]
  > = initPaginateListResponseGlobal<ActionsListResponse[]>([]);
  @observable ActionsData: ActionsListResponse | null = null;

  getActions = flow(function* (
    this: DataStore,
    payload: ConfigPaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      action: { ...this.loading.action, listLoading: true },
    };
    this.ActionsListResponse = initPaginateListResponseGlobal<
      ActionsListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<ActionsListResponse[]>
      > = yield api.getActions(payload);
      this.ActionsListResponse = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        action: { ...this.loading.action, listLoading: false },
      };
    }
  }).bind(this);

  getAction = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      action: { ...this.loading.action, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.ActionsData = null;
    try {
      const response: APIResponse<ActionsListResponse> = yield api.getAction(
        ID
      );
      this.ActionsData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        action: { ...this.loading.action, itemLoading: false },
      };
    }
  }).bind(this);

  saveAction = flow(function* (this: DataStore, payload: ActionsPayload) {
    this.loading = {
      ...this.loading,
      action: { ...this.loading.action, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveAction(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        action: { ...this.loading.action, saveDataLoading: false },
      };
    }
  }).bind(this);

  deleteAction = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      action: { ...this.loading.action, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteAction(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        action: { ...this.loading.action, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable EquipmentTypesListData: PaginatedListResponse<
    EquipmentTypesListResponse[]
  > = initPaginateListResponseGlobal<EquipmentTypesListResponse[]>([]);
  @observable EquipmentTypeData: EquipmentTypesListResponse | null = null;

  getEquipmentTypes = flow(function* (
    this: DataStore,
    payload: ConfigPaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      equipmentType: { ...this.loading.equipmentType, listLoading: true },
    };
    this.EquipmentTypesListData = initPaginateListResponseGlobal<
      EquipmentTypesListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<EquipmentTypesListResponse[]>
      > = yield api.getEquipmentTypes(payload);
      this.EquipmentTypesListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        equipmentType: { ...this.loading.equipmentType, listLoading: false },
      };
    }
  }).bind(this);

  getEquipmentType = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      equipmentType: { ...this.loading.equipmentType, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.EquipmentTypeData = null;
    try {
      const response: APIResponse<EquipmentTypesListResponse> =
        yield api.getEquipmentType(ID);
      this.EquipmentTypeData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        equipmentType: { ...this.loading.equipmentType, itemLoading: false },
      };
    }
  }).bind(this);

  saveEquipmentType = flow(function* (
    this: DataStore,
    payload: EquipmentTypesPayload
  ) {
    this.loading = {
      ...this.loading,
      equipmentType: { ...this.loading.equipmentType, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveEquipmentType({
        ...payload
      });
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        equipmentType: {
          ...this.loading.equipmentType,
          saveDataLoading: false,
        },
      };
    }
  }).bind(this);

  deleteEquipmentType = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      branch: { ...this.loading.branch, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteEquipmentType(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        equipmentType: { ...this.loading.equipmentType, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable DepartmentListData: PaginatedListResponse<
    DepartmentListResponse[]
  > = initPaginateListResponseGlobal<DepartmentListResponse[]>([]);
  @observable DepartmentData: DepartmentListResponse | null = null;

  getDepartments = flow(function* (
    this: DataStore,
    payload: ConfigPaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      department: { ...this.loading.department, listLoading: true },
    };
    this.DepartmentListData = initPaginateListResponseGlobal<
      DepartmentListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<DepartmentListResponse[]>
      > = yield api.getDepartments(payload);
      this.DepartmentListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        department: { ...this.loading.department, listLoading: false },
      };
    }
  }).bind(this);

  getDepartment = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      branch: { ...this.loading.branch, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.DepartmentData = null;
    try {
      const response: APIResponse<DepartmentListResponse> =
        yield api.getDepartment(ID);
      this.DepartmentData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        branch: { ...this.loading.branch, itemLoading: false },
      };
    }
  }).bind(this);

  saveDepartment = flow(function* (
    this: DataStore,
    payload: DepartmentPayload
  ) {
    this.loading = {
      ...this.loading,
      department: { ...this.loading.department, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveDepartment(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        department: { ...this.loading.department, saveDataLoading: false },
      };
    }
  }).bind(this);

  deleteDepartment = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      department: { ...this.loading.department, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteDepartment(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        department: { ...this.loading.department, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable MocCategoryListData: PaginatedListResponse<
    MocCategoryListResponse[]
  > = initPaginateListResponseGlobal<MocCategoryListResponse[]>([]);
  @observable MocCategoryData: MocCategoryListResponse | null = null;

  getMocCategories = flow(function* (
    this: DataStore,
    payload: PaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      mocCategory: { ...this.loading.mocCategory, listLoading: true },
    };
    this.MocCategoryListData = initPaginateListResponseGlobal<
      MocCategoryListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<MocCategoryListResponse[]>
      > = yield api.getMocCategories(payload);
      this.MocCategoryListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mocCategory: { ...this.loading.mocCategory, listLoading: false },
      };
    }
  }).bind(this);

  getMocCategory = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      mocCategory: { ...this.loading.mocCategory, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.MocCategoryData = null;
    try {
      const response: APIResponse<MocCategoryListResponse> =
        yield api.getMocCategory(ID);
      this.MocCategoryData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mocCategory: { ...this.loading.mocCategory, itemLoading: false },
      };
    }
  }).bind(this);

  saveMocCategory = flow(function* (
    this: DataStore,
    payload: MocCategoryPayload
  ) {
    this.loading = {
      ...this.loading,
      mocCategory: { ...this.loading.mocCategory, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveMocCategory(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mocCategory: { ...this.loading.mocCategory, saveDataLoading: false },
      };
    }
  }).bind(this);

  deleteMocCategory = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      mocCategory: { ...this.loading.mocCategory, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteMocCategory(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mocCategory: { ...this.loading.mocCategory, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable MocListData: PaginatedListResponse<MocListResponse[]> =
    initPaginateListResponseGlobal<MocListResponse[]>([]);
  @observable MocData: MocPayload | null = null;

  getMocs = flow(function* (
    this: DataStore,
    payload: ConfigPaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      moc: { ...this.loading.moc, listLoading: true },
    };
    this.MocListData = initPaginateListResponseGlobal<MocListResponse[]>([]);

    try {
      const response: APIResponse<PaginatedListResponse<MocListResponse[]>> =
        yield api.getMocs(payload);
      this.MocListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        moc: { ...this.loading.moc, listLoading: false },
      };
    }
  }).bind(this);

  @observable MocDetailListData: MocListResponse[] | null = null;

  getMocDetail = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      moc: { ...this.loading.moc, listLoading: true },
    };
    this.MocDetailListData = null;
    try {
      const response: APIResponse<MocListResponse> = yield api.getMocDetail(ID);
      this.MocDetailListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        moc: { ...this.loading.moc, listLoading: false },
      };
    }
  }).bind(this);

  getMoc = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      moc: { ...this.loading.moc, itemLoading: true },
    };

    this.saveDataResponse = null;
    this.MocData = null;
    try {
      const response: APIResponse<MocPayload> = yield api.getMoc(ID);
      this.MocData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        moc: { ...this.loading.moc, itemLoading: false },
      };
    }
  }).bind(this);
  saveMocs = flow(async function* (this: DataStore, payload: MocPayload) {
    this.loading = {
      ...this.loading,
      moc: { ...this.loading.moc, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      const mandatesRequiredData: MocMaindatesRequestp[] = [];
      const promises: Promise<any>[] = []; // Array to store promises

      for (const singleMandate of payload.mocMaindatesRequest) {
        const formData = new FormData();

        const file: any = singleMandate?.mediaStoreId;
        if (file && !isGUID(singleMandate?.mediaStoreId)) {
          formData.append("mediaFile", file, file.name);

          if (file.type.startsWith("image/")) {
            formData.append("Type", UploadMediaTypes.Image);
          } else if (
            file.type.startsWith("application/") ||
            file.type.startsWith("text/")
          ) {
            formData.append("Type", UploadMediaTypes.Document);
          }

          // Create a promise for each loop iteration and push it to the array
          promises.push(
            api
              .uploadMedia(formData)
              .then((filePath) => {
                mandatesRequiredData.push({
                  id: singleMandate.id,
                  maindatesId: singleMandate.maindatesId,
                  statusId: singleMandate.statusId,
                  mediaStoreId: filePath?.data ?? null,
                  remarks: singleMandate.remarks,
                });
              })
              .catch((error) => {})
          );
        } else {
          mandatesRequiredData.push({
            id: singleMandate.id,
            maindatesId: singleMandate.maindatesId,
            statusId: singleMandate.statusId,
            mediaStoreId: null,
            remarks: singleMandate.remarks,
          });
        }
      }
      await Promise.all(promises);
      this.saveDataResponse = await api.saveMoc({
        ...payload,
        mocMaindatesRequest: mandatesRequiredData ?? [],
      });
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        moc: { ...this.loading.moc, saveDataLoading: false },
      };
    }
  }).bind(this);

  getChangeMocConsidrationStatus = flow(function* (
    this: DataStore,
    payload: ChangeMocConsidrationStatusPayload
  ) {
    this.loading = {
      ...this.loading,
      mocChangeStatus: {
        ...this.loading.mocChangeStatus,
        saveDataLoading: true,
      },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.ChangeMocConsidrationStatus(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mocChangeStatus: {
          ...this.loading.mocChangeStatus,
          saveDataLoading: false,
        },
      };
    }
  }).bind(this);

  deleteMoc = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      moc: { ...this.loading.moc, deleteLoading: true },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteMoc(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        moc: { ...this.loading.moc, deleteLoading: false },
      };
    }
  }).bind(this);

  @observable MocConsiderationsActionListData: PaginatedListResponse<
    MocConsidrationsActionsResponse[]
  > = initPaginateListResponseGlobal<MocConsidrationsActionsResponse[]>([]);
  @observable
  MocConsiderationsActionData: MocConsidrationsActionsResponse | null = null;

  getMocConsiderationsActions = flow(function* (
    this: DataStore,
    payload: ConfigPaginatedListPayload & { mocId?: string }
  ) {
    this.loading = {
      ...this.loading,
      mocConsiderationAction: {
        ...this.loading.mocConsiderationAction,
        listLoading: true,
      },
    };
    this.MocConsiderationsActionListData = initPaginateListResponseGlobal<
      MocConsidrationsActionsResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<MocConsidrationsActionsResponse[]>
      > = yield api.getMocConsiderationActions(payload);
      this.MocConsiderationsActionListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mocConsiderationAction: {
          ...this.loading.mocConsiderationAction,
          listLoading: false,
        },
      };
    }
  }).bind(this);

  getMocConsiderationsAction = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      mocConsiderationAction: {
        ...this.loading.mocConsiderationAction,
        itemLoading: true,
      },
    };

    this.saveDataResponse = null;
    this.branchData = null;
    try {
      const response: APIResponse<MocConsidrationsActionsResponse> =
        yield api.getMocConsiderationAction(ID);
      this.MocConsiderationsActionData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mocConsiderationAction: {
          ...this.loading.mocConsiderationAction,
          itemLoading: false,
        },
      };
    }
  }).bind(this);

  saveMocConsiderationsAction = flow(function* (
    this: DataStore,
    payload: MocConsidrationsActionsPayload
  ) {
    this.loading = {
      ...this.loading,
      mocConsiderationAction: {
        ...this.loading.mocConsiderationAction,
        saveDataLoading: true,
      },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveMocConsiderationAction(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mocConsiderationAction: {
          ...this.loading.mocConsiderationAction,
          saveDataLoading: false,
        },
      };
    }
  }).bind(this);

  deleteMocConsiderationsAction = flow(function* (this: DataStore, ID: string) {
    this.loading = {
      ...this.loading,
      mocConsiderationAction: {
        ...this.loading.mocConsiderationAction,
        deleteLoading: true,
      },
    };

    this.deleteDataResponse = null;
    try {
      this.deleteDataResponse = yield api.deleteMocConsiderationAction(ID);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mocConsiderationAction: {
          ...this.loading.mocConsiderationAction,
          deleteLoading: false,
        },
      };
    }
  }).bind(this);

  saveMocCaonsiderationRemarks = flow(function* (
    this: DataStore,
    payload: MocConsiderationRemarksPayload
  ) {
    this.loading = {
      ...this.loading,
      mocConsidrationRemarks: {
        ...this.loading.mocConsidrationRemarks,
        saveDataLoading: true,
      },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveMocCaonsiderationRemarks(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mocConsidrationRemarks: {
          ...this.loading.mocConsidrationRemarks,
          saveDataLoading: false,
        },
      };
    }
  }).bind(this);

  @observable MocCaonsiderationRemarksListData: PaginatedListResponse<
    MocCaonsiderationRemarksListResponse[]
  > = initPaginateListResponseGlobal<MocCaonsiderationRemarksListResponse[]>(
    []
  );

  getMocCaonsiderationRemarks = flow(function* (
    this: DataStore,
    payload: ConfigPaginatedListPayload
  ) {
    this.loading = {
      ...this.loading,
      mocConsidrationRemarks: {
        ...this.loading.mocConsidrationRemarks,
        listLoading: true,
      },
    };
    this.MocCaonsiderationRemarksListData = initPaginateListResponseGlobal<
      MocCaonsiderationRemarksListResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<MocCaonsiderationRemarksListResponse[]>
      > = yield api.getMocCaonsiderationRemarks(payload);
      this.MocCaonsiderationRemarksListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mocConsidrationRemarks: {
          ...this.loading.mocConsidrationRemarks,
          listLoading: false,
        },
      };
    }
  }).bind(this);

  @observable MocApproversListData: PaginatedListResponse<
    MocApprovalsResponse[]
  > = initPaginateListResponseGlobal<MocApprovalsResponse[]>([]);

  getMocsApprovers = flow(function* (
    this: DataStore,
    payload: ConfigPaginatedListPayload & {isApprover: boolean}
  ) {
    this.loading = {
      ...this.loading,
      mocApprovers: { ...this.loading.mocApprovers, listLoading: true },
    };
    this.MocApproversListData = initPaginateListResponseGlobal<
      MocApprovalsResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<MocApprovalsResponse[]>
      > = yield api.getMocsApprovers(payload);
      this.MocApproversListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mocApprovers: { ...this.loading.mocApprovers, listLoading: false },
      };
    }
  }).bind(this);

  saveMocConsidrationMedia = flow(function* (
    this: DataStore,
    payload: MocConsidrationMediaPayload
  ) {
    this.loading = {
      ...this.loading,
      mocConsidrationMedia: {
        ...this.loading.mocConsidrationMedia,
        saveDataLoading: true,
      },
    };

    this.saveDataResponse = null;
    try {
      const formData = new FormData();
      const file: any = payload.mediaStoreId;
      formData.append("mediaFile", file, file.name);
      if (file.type.startsWith("image/")) {
        formData.append("Type", UploadMediaTypes.Image);
      } else if (
        file.type.startsWith("application/") ||
        file.type.startsWith("text/")
      ) {
        formData.append("Type", UploadMediaTypes.Document);
      }
      const ImagePathId = yield file ? api.uploadMedia(formData) : null;
      this.saveDataResponse = yield api.saveMocConsidrationMedia({
        ...payload,
        mediaStoreId: ImagePathId.data,
      });
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mocConsidrationMedia: {
          ...this.loading.mocConsidrationMedia,
          saveDataLoading: false,
        },
      };
    }
  }).bind(this);

  @observable MocConsiderationsMediaListData: PaginatedListResponse<
    MocConsidrationMediaResponse[]
  > = initPaginateListResponseGlobal<MocConsidrationMediaResponse[]>([]);

  getMocConsiderationsMedias = flow(function* (
    this: DataStore,
    payload: ConfigPaginatedListPayload & { mediaTypeId?: string }
  ) {
    this.loading = {
      ...this.loading,
      mocConsidrationMedia: {
        ...this.loading.mocConsidrationMedia,
        listLoading: true,
      },
    };
    this.MocConsiderationsMediaListData = initPaginateListResponseGlobal<
      MocConsidrationMediaResponse[]
    >([]);

    try {
      const response: APIResponse<
        PaginatedListResponse<MocConsidrationMediaResponse[]>
      > = yield api.getMocConsiderationsMedias(payload);
      this.MocConsiderationsMediaListData = response.data;
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mocConsidrationMedia: {
          ...this.loading.mocConsidrationMedia,
          listLoading: false,
        },
      };
    }
  }).bind(this);

  getMocApprovel = flow(function* (
    this: DataStore,
    payload: MocApproversPayload
  ) {
    this.loading = {
      ...this.loading,
      mocApprovel: { ...this.loading.mocApprovel, saveDataLoading: true },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.mocApprovers(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        mocApprovel: { ...this.loading.mocApprovel, saveDataLoading: false },
      };
    }
  }).bind(this);

  changeMocStatus = flow(function* (
    this: DataStore,
    payload: MocChangeStatusPayload
  ) {
    this.loading = {
      ...this.loading,
      changeMocStatus: {
        ...this.loading.changeMocStatus,
        saveDataLoading: true,
      },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.changeMocStatus(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        changeMocStatus: {
          ...this.loading.changeMocStatus,
          saveDataLoading: false,
        },
      };
    }
  }).bind(this);

  setMarkAsCompleted = flow(function* (
    this: DataStore,
    payload: MarkAsCompletedPayload
  ) {
    this.loading = {
      ...this.loading,
      setMarkAsCompleted: {
        ...this.loading.setMarkAsCompleted,
        saveDataLoading: true,
      },
    };
    this.saveDataResponse = null;
    try {
      let imageUrl = null;
      if (payload.mediaStoreId.name) {
        const formData = new FormData();
        const file: any = payload.mediaStoreId;
        formData.append(
          "mediaFile",
          file,
          file.name
        );
        formData.append('Type', UploadMediaTypes.Document); 
        imageUrl = yield file ? api.uploadMedia(formData) : null;
      }
      this.saveDataResponse = yield api.MarkAsCompleted({ ...payload, mediaStoreId: payload.mediaStoreId ? imageUrl.data : null});
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        setMarkAsCompleted: {
          ...this.loading.setMarkAsCompleted,
          saveDataLoading: false,
        },
      };
    }
  }).bind(this);

  saveMaindatesRemarksAgreement = flow(function* (
    this: DataStore,
    payload: MocMaindatesConsidrationAgreements
  ) {
    this.loading = {
      ...this.loading,
      maindatesRemarksAgreement: {
        ...this.loading.maindatesRemarksAgreement,
        saveDataLoading: true,
      },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveMaindateRemarksAgreement(payload);
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        maindatesRemarksAgreement: {
          ...this.loading.maindatesRemarksAgreement,
          saveDataLoading: false,
        },
      };
    }
  }).bind(this);

  saveConsidrationRemarksAgreement = flow(function* (
    this: DataStore,
    payload: MocMaindatesConsidrationAgreements
  ) {
    this.loading = {
      ...this.loading,
      considrationRemarksAgreement: {
        ...this.loading.considrationRemarksAgreement,
        saveDataLoading: true,
      },
    };

    this.saveDataResponse = null;
    try {
      this.saveDataResponse = yield api.saveConsidrationRemarksAgreement(
        payload
      );
    } catch (error) {
      this.errorMessage = "Unable to load data";
    } finally {
      this.loading = {
        ...this.loading,
        considrationRemarksAgreement: {
          ...this.loading.considrationRemarksAgreement,
          saveDataLoading: false,
        },
      };
    }
  }).bind(this);

  @observable MocConsidrationAgreementLogsListData: PaginatedListResponse<
  MocMaindatesConsidrationAgreements[]
> = initPaginateListResponseGlobal<MocMaindatesConsidrationAgreements[]>([]);

getMocConsidrationAgreementLogs = flow(function* (
  this: DataStore,
  consId: string
) {
  this.loading = {
    ...this.loading,
    considrationRemarksAgreement: { ...this.loading.considrationRemarksAgreement, listLoading: true },
  };
  this.MocConsidrationAgreementLogsListData = initPaginateListResponseGlobal<
  MocMaindatesConsidrationAgreements[]
  >([]);

  try {
    const response: APIResponse<
      PaginatedListResponse<MocMaindatesConsidrationAgreements[]>
    > = yield api.getMocConsidrationAgreementLogs(consId);
    this.MocConsidrationAgreementLogsListData = response.data;
  } catch (error) {
    this.errorMessage = "Unable to load data";
  } finally {
    this.loading = {
      ...this.loading,
      considrationRemarksAgreement: { ...this.loading.considrationRemarksAgreement, listLoading: false },
    };
  }
}).bind(this);

@observable MocMaindatesAgreementLogsListData: PaginatedListResponse<
MocMaindatesConsidrationAgreements[]
> = initPaginateListResponseGlobal<MocMaindatesConsidrationAgreements[]>([]);

getMocMaindatesAgreementLogs = flow(function* (
this: DataStore,
maindatesId: string
) {
this.loading = {
  ...this.loading,
  maindatesRemarksAgreement: { ...this.loading.maindatesRemarksAgreement, listLoading: true },
};
this.MocMaindatesAgreementLogsListData = initPaginateListResponseGlobal<
MocMaindatesConsidrationAgreements[]
>([]);

try {
  const response: APIResponse<
    PaginatedListResponse<MocMaindatesConsidrationAgreements[]>
  > = yield api.getMocMaindatesAgreementLogs(maindatesId);
  this.MocMaindatesAgreementLogsListData = response.data;
} catch (error) {
  this.errorMessage = "Unable to load data";
} finally {
  this.loading = {
    ...this.loading,
    maindatesRemarksAgreement: { ...this.loading.maindatesRemarksAgreement, listLoading: false },
  };
}
}).bind(this);

@observable MocConsidrationLogsListData: PaginatedListResponse<MocConsidrationLogsListRespose[]> =
initPaginateListResponseGlobal<MocConsidrationLogsListRespose[]>([]);

getMocConsidrationLogs = flow(function* (
this: DataStore,
mocId: string
) {
this.loading = {
  ...this.loading,
  mocConsiderationLogs: { ...this.loading.mocConsiderationLogs, listLoading: true },
};
this.MocConsidrationLogsListData = initPaginateListResponseGlobal<MocConsidrationLogsListRespose[]>([]);
try {
  const response: APIResponse<PaginatedListResponse<MocConsidrationLogsListRespose[]>> =
    yield api.getMocConsidrationLogs(mocId);
  this.MocConsidrationLogsListData = response;
} catch (error) {
  this.errorMessage = "Unable to load data";
} finally {
  this.loading = {
    ...this.loading,
    mocConsiderationLogs: { ...this.loading.mocConsiderationLogs, listLoading: false },
  };
}
}).bind(this);

@observable MocMaindatesLogsListData: PaginatedListResponse<MocMaindatesLogsListResponse[]> =
initPaginateListResponseGlobal<MocConsidrationLogsListRespose[]>([]);

getMocMaindatesLogs = flow(function* (
this: DataStore,
mocId: string
) {
this.loading = {
  ...this.loading,
  mocMaindatesLogs: { ...this.loading.mocMaindatesLogs, listLoading: true },
};
this.MocMaindatesLogsListData = initPaginateListResponseGlobal<MocMaindatesLogsListResponse[]>([]);

try {
  const response: APIResponse<PaginatedListResponse<MocMaindatesLogsListResponse[]>> =
    yield api.getMocMaindatesLogs(mocId);
  this.MocMaindatesLogsListData = response;
} catch (error) {
  this.errorMessage = "Unable to load data";
} finally {
  this.loading = {
    ...this.loading,
    mocMaindatesLogs: { ...this.loading.mocMaindatesLogs, listLoading: false },
  };
}
}).bind(this);

  @observable isSideBarVisible: boolean = false;
  @observable isSideBarUpdateMode: boolean = false;

  handleShowUpdate = flow(function* (
    this: DataStore,
    show: boolean,
    isUpdate: boolean
  ) {
    this.isSideBarVisible = show;
    this.isSideBarUpdateMode = isUpdate;
  }).bind(this);
}

const initLoading: DataLoading = {
  listLoading: false,
  saveDataLoading: false,
  deleteLoading: false,
  itemLoading: false,
};
