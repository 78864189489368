import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import * as Icon from "react-feather";
import { history } from "../../../history";
import { observer } from "mobx-react-lite";
import { RemoveAuthToken } from "../../../utility/Common";
import "./NavbarUser.scss";

interface Props {
  userName: string;
  userImage: string;
}

export const NavbarUser: React.FC<Props> = observer(
  ({ userName, userImage }) => {
    const UserDropdown = () => {
      return (
        <DropdownMenu right>
          <DropdownItem
            tag="a"
            onClick={() => {
              history.push("/profile");
            }}
          >
            <Icon.User size={14} className="mr-50" />
            <span className="align-middle">Edit Profile</span>
          </DropdownItem>

          <DropdownItem divider />
          <DropdownItem
            tag="a"
            onClick={() => {
              RemoveAuthToken();
              history.push("/login");
            }}
          >
            <Icon.Power size={14} className="mr-50" />
            <span className="align-middle">Log Out</span>
          </DropdownItem>
        </DropdownMenu>
      );
    };

    return (
      <ul className="nav navbar-nav navbar-nav-user float-right align-items-baseline">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600 loggedin-user-name">
                {userName}
              </span>
            </div>
            <span data-tour="user" style={{ height: "45px" }} title={userName}>
              <img
                src={userImage}
                alt="User Image"
                className="user-profile-style"
              />
            </span>
          </DropdownToggle>
          {UserDropdown()}
        </UncontrolledDropdown>
      </ul>
    );
  }
);