import * as request from "superagent";
import { errorCallback } from "utility/dm.api";
import { apiURL } from "utility/setting";
import { GetAuthToken } from "../Common";

const token = () => `Bearer ${GetAuthToken()}`;

export const getDashboardTotalMocStatusDetailReport = (
  payload: DashBoardReportsPaylod
): Promise<APIResponse<MocStatusReportResponse[]>> =>
  request
    .post(`${apiURL}/Report/getmocbystatus`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getDashboardActionCountReport = (
  payload: DashboardActionCountReportPayload
): Promise<APIResponse<number>> =>
  request
    .post(`${apiURL}/Report/getactionscountbystatus`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));

export const getDashboardActionTrendReport = (
  payload: DashboardMocActionTrendReportPayload
): Promise<APIResponse<[]>> =>
  request
    .post(`${apiURL}/Report/actionstrendbystatus`, payload)
    .set("Authorization", token())
    .then((r: { body: any }) => r.body)
    .catch((err) => errorCallback(err.response));